import { Component, OnInit } from "@angular/core";
// import { embedDashboard } from "@superset-ui/embedded-sdk";
import { embedDashboard } from "@preset-sdk/embedded";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as jsonData from '../assets/config/config.json';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "apache-superset";
  showSuperSet: boolean = true;
  firsttime = 0;
  ConfigData:any;
  constructor(private http: HttpClient) {}

  async ngOnInit() {
    this.ConfigData = jsonData
    localStorage.clear();
    const token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImZpcnN0X25hbWUiOiJNeUFwcCBVc2VyIiwibGFzdF9uYW1lIjoiTXlBcHAgVXNlciIsInVzZXJuYW1lIjoibXlhcHB1c2VyIn0sInJlc291cmNlcyI6W3sidHlwZSI6ImRhc2hib2FyZCIsImlkIjoiMWFjMmEwMTMtOTdmMC00YTk2LThjMjAtYjlkYTcwOGVhYzU1In1dLCJybHNfcnVsZXMiOlt7ImNsYXVzZSI6ImN1c3RvbWVyX2lkPTUifV0sImlhdCI6MTY3NjU1Mjk4My41ODUxMDI4LCJleHAiOjE2NzY1NTMyODMuNTg1MTAyOCwiYXVkIjoiaHR0cDovL3N1cGVyc2V0OjgwODgvIiwidHlwZSI6Imd1ZXN0In0.jrIpT-mFq0POGKP7wQ7dZdJ9WQ_9dOt3zEMwpw2WMqg";

    this.LoginSuperSet();
  }

  LoginSuperSet() {
    let url =
      "https://acengage-apachesuperset-dev.devtomaster.com/api/v1/security/login";
    let userDetails = this.ConfigData.default.userDetails
    this.http.post(url, userDetails).subscribe((userResponse: any) => {
      if (userResponse) {
        const token =
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImZpcnN0X25hbWUiOiJNeUFwcCBVc2VyIiwibGFzdF9uYW1lIjoiTXlBcHAgVXNlciIsInVzZXJuYW1lIjoibXlhcHB1c2VyIn0sInJlc291cmNlcyI6W3sidHlwZSI6ImRhc2hib2FyZCIsImlkIjoiMWFjMmEwMTMtOTdmMC00YTk2LThjMjAtYjlkYTcwOGVhYzU1In1dLCJybHNfcnVsZXMiOlt7ImNsYXVzZSI6ImN1c3RvbWVyX2lkPTUifV0sImlhdCI6MTY3NjU1NjI0OS41ODIzMjc2LCJleHAiOjE2NzY1NTY1NDkuNTgyMzI3NiwiYXVkIjoiaHR0cDovL3N1cGVyc2V0OjgwODgvIiwidHlwZSI6Imd1ZXN0In0.vtiuhqKfs7GZ4s-jj1uh9jIOzBWGrBARuLTWYCIExEs";
        localStorage.setItem("LoginToken", userResponse.access_token);
        this.fetchGuestTokenFromBackend();
      }
    });
  }

  fetchGuestTokenFromBackend() {
    let inputData: any =this.ConfigData.default.TokenConfig;
    let bearer_token = localStorage.getItem("LoginToken");

    var reqHeader = new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    });

    let url =
      "https://acengage-apachesuperset-dev.devtomaster.com/api/v1/security/guest_token/";

    this.http
      .post(url, inputData, {
        headers: reqHeader,
      })
      .subscribe((response: any) => {
        let data = response.token;
        this.showSuperSet = false;
        let superset = embedDashboard({
          id: "1ac2a013-97f0-4a96-8c20-b9da708eac55",
          supersetDomain: "https://acengage-apachesuperset-dev.devtomaster.com",
          mountPoint: document.getElementById("my-superset-container"),
          fetchGuestToken: async () => data,
          dashboardUiConfig: { hideTitle: true, hideChartControls: false },
        });

        setInterval(() => {
          let iframe = document.getElementById("my-superset-container")
            .children[0];
          if (iframe) {
            iframe["style"].width = "100%";
            iframe["style"].height = "92vh";
          }
        }, 0);
      });
  }
}
